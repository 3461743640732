import * as React from "react"
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout className="pagebuilder">
    <div className="text-center flex flex-col items-center content-center justify-center relative max-w-screen-sm mx-auto">
      <h1 className="font-sansserif uppercase">404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </div>
  </Layout>
)

export default NotFoundPage
